import React from "react";
import { Container } from "react-bootstrap";
import { Link } from '../../types/Link';
import './Footer.scss';

interface LinksProps {
  links: Link[];
}


const Footer: React.FC<LinksProps> = ({links}) => {
  const companyName = 'JYU株式会社';
  const phoneNumber = '070-4473-9388';
  const emailAddress = 'aizawa.yuta@ jyu-innovation.com';

  return (
    <footer className="footer py-5">
      <Container>
        <hr/>
        <div className="d-flex flex-row flex-wrap justify-content-between mb-3">
          <div>
            <span className="title">{companyName}</span>
            <span>-</span>
            <span>TEL: {phoneNumber}</span>
            <span>/</span>
            <span>E-Mail: {emailAddress}</span>
          </div>

          <div className="text-end">
            <span>
            JYU INNOVATION STUDIO © 2023 All rights reserved.
            </span>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
