import React from "react";
import HeroSection from '../components/RetailPage/HeroSection';
import BusinessOverview from "../components/RetailPage/BusinessOverview";
import Strengths from '../components/RetailPage/OurStrengths';
import { HeroType } from '../types/Hero';
import Contact from "../components/common/Contact";


const Retail: React.FC = () => {

  const hero: HeroType = {
    mainMessage : "日本の品質を台湾へ",
    imageUrl : "/images/section/retail_hero.jpeg",
    imageIcon: 'images/section/retail_icon.svg',
    // overlayColor: 'rgba(92, 146, 145, 1)',
  }

  const businessDetails = [
    {
      titleJapanese: '日本の美を台湾へ',
      titleEnglish: 'Bringing Japan\'s Beauty to Taiwan',
      description: '日本の上質な製品、台湾へ/選りすぐりの日本の美を伝える',
      imageSrc: "/images/section/retail_japanese_texture_01.jpg",
    },
    // {
    //   titleJapanese: 'メーカー・サプライヤーへの支援',
    //   titleEnglish: 'Supporting Manufacturers & Suppliers',
    //   description: '売上拡大と販売先市場の拡大を支援します。台湾市場向けのリブランディングからマーケティング、販売事務の手間削減まで、新たなビジネスの展開を進めましょう。',
    //   imageSrc: supportingManufacturersImg,
    // },
    {
      titleJapanese: '台湾の感性にフィット',
      titleEnglish: 'Tailored Promotion for Taiwan',
      description: '台湾の感性や習慣に合致した戦略展開/製品の良さを活かしたリブランディング',
      imageSrc: "/images/section/retail_japanese_texture_02.jpg",
    },
    {
      titleJapanese: '品質志向の顧客へ',
      titleEnglish: 'For Quality-Conscious Consumers',
      description: '高品質な商品を求める顧客へ/台湾内外の独自ネットワーク活用/台湾独自のモールも活用',
      imageSrc: "/images/section/retail_japanese_texture_03.jpg",
    },
  ];

  
  const strengthsData = [
    {
      title: '台湾での販売実績',
      content: '当社はこれまで台湾市場での販売実績と成功事例が豊富です。私たちは常にお客様のビジネスの成功を目指し、製品開発やマーケティングなど、あらゆる面でお客様をサポートいたします。',
      image: '/images/section/retail_strength_01.jpg',
      btn: {
        path: "/images/section/retail_strength_01.jpg",
        label: "台湾におけるECサイト"
      }
    },
    {
      title: '豊富なデザイン実績',
      content: '当社のデザイナーたちは台湾の文化や市場に適したデザイン力を持っています。私たちはお客様のビジネスにとって最適なデザインを提供し、ビジネスの成功に貢献します。',
      image: '/images/section/retail_strength_02.jpg',
      btn: {
        path: "/design",
        label: "デザイン実績"
      }
    },
    {
      title: '台湾における人脈',
      content: '当社は台湾市場において、経営者との繋がりや高単価商品の販売力を持っています。私たちはお客様のビジネスに必要な情報やリソースを提供し、長期的なビジネス関係を築いていきます。',
      image: '/images/section/retail_strength_03.jpg',
    },
    {
      title: '台湾市場の知見',
      content: '私たちは台湾市場の消費者嗜好・競合など、台湾市場に精通しています。私たちはお客様のビジネスに必要な情報を提供し、お客様のビジネスの成功に貢献します。',
      image: '/images/section/retail_strength_04.jpg',
    },
    {
      title: 'IT及び海外展開の経験',
      content: '当社はITを活用した効率化、海外展開に関する豊富な経験があります。私たちはお客様のビジネスを成功に導くために、常に最新の情報や技術を提供していきます。',
      image: '/images/section/retail_strength_05.jpg',
      btn: {
        path: "/it-consulting",
        label: "IT実績"
      }
    },
  ];

  return (
    <div>
      <HeroSection hero={hero} />
      <BusinessOverview  businessDetails={businessDetails}/>
      <Strengths strengthsData={strengthsData}/>
      <section className="contactUs">
        <Contact title="お問い合わせ" displayForm={true} displayLineRegistration={true}/>
      </section>
    </div>
  );
};

export default Retail;
