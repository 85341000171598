import React, { useState } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import './Portfolio.scss';
import DetailModal from '../common/DetailModal';

// type
import { Case, DesignCase } from '../../types/Case';

type PortfolioProps = {
  designCases: DesignCase[];
};

const Portfolio: React.FC<PortfolioProps> = ({ designCases }) => {
  const [flippedImage, setFlippedImage] = useState<number | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentCase, setCurrentCase] = useState<Case | null>(null);

  const handleMouseEnter = (index: number) => {
    setFlippedImage(index);
  };

  const handleMouseLeave = (index: number) => {
    if (flippedImage === index) {
      setFlippedImage(null);
    }
  };

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const handleShowCase = (caseItem: Case) => {
    setCurrentCase(caseItem);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  return (
    <section>

    <h2 className="h2-title">
      <div className="english">Portfolio</div>
      <div className="japanese">私たちの実績</div>
    </h2>
    <Container>
      <Row>
        {designCases.map((designCase, index) => (
          <Col key={index} sm={12} md={6} lg={4} xl={3} className="mb-4">
            <div
              className={`flip-card${flippedImage === index ? ' flipped' : ''}`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  {!isLoaded && (
                    <div className="spinner-border text-primary position-absolute top-50 start-50 translate-middle" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                  <Image
                    onLoad={handleImageLoad}
                    style={{ display: isLoaded ? 'block' : 'none' }}
                    className="img-fluid"
                    src={designCase.src}
                    alt={designCase.alt}
                  />
                </div>
                <div className="flip-card-back">
                  <div className="image-info">
                    <h3>{designCase.alt}</h3>
                    <p>{designCase.info}</p>
                    <Button onClick={() => handleShowCase(designCase.caseItem)}>詳細を表示</Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <DetailModal show={showModal} caseItem={currentCase} onClose={handleCloseModal} />
    </Container>
  </section>
);
};

export default Portfolio;
