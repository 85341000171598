import React from "react";
import "./CircularText3.scss";

interface CircularTextProps {
  text: string;
  startAngle: number;
  endAngle: number;
  radius: number;
  fontSize: number;
  duration: number;
}

const CircularText: React.FC<CircularTextProps> = ({
  text,
  startAngle,
  endAngle,
  radius,
  fontSize,
  duration,
}) => {
  const characters = text.split("");
  const diameter = radius * 2;

  const generateClipPath = (startAngle: number, endAngle: number) => {

    const calcPoint = (angle: number): string => {
      const x = Math.cos(angle * (Math.PI / 180));
      const y = Math.sin(angle * (Math.PI / 180));

      let xPercentage = '100%';
      let yPercentage = '100%';

      if (x >= y && Math.abs(x) >= Math.abs(y)) {
        xPercentage = '100%';
        yPercentage = `${50 + 50 * - Math.tan(angle * (Math.PI / 180))}%`
      } else if (x <= y && Math.abs(x) <= Math.abs(y)) {
        xPercentage = `${50 + 50 * - Math.tan((angle - 90) * (Math.PI / 180))}%`
        yPercentage = '0%';
      } else if (x <= y && Math.abs(x) >= Math.abs(y)) {
        xPercentage = '0%';
        yPercentage = `${50 + 50 * Math.tan((angle - 180) * (Math.PI / 180))}%`
      } else if (x >= y && Math.abs(x) <= Math.abs(y)) {
        xPercentage = `${50 + 50 * Math.tan((angle - 270) * (Math.PI / 270))}%`
        yPercentage = '100%';
      }

      return xPercentage + ' ' + yPercentage;
    }
  
    let clipPath = `polygon(50% 50%, ${calcPoint(startAngle)}`;
  
    const additionalAngles = [-135, -45, 45, 135, 225, 315, 405, 495]; // 追加する角度
    const additionalPoints = ['0% 100%', '100% 100%', '100% 0%', '0% 0%', '0% 100%', '100% 100%', '100% 0%', '0% 0%']; // 追加する角度
    
    additionalAngles.forEach((angle,i) => {
      if (angle > startAngle && angle < endAngle) {
        clipPath = `${clipPath}, ${additionalPoints[i]}`;
      }
    });
    clipPath = `${clipPath}, ${calcPoint(endAngle)})`
    return clipPath;
  };
  

  return (
    <div style={{ clipPath: generateClipPath(startAngle, endAngle) }}>
      <div
        className="circle-text"
        style={{
          width: `${diameter}rem`,
          height: `${diameter}rem`,
          fontSize: `${fontSize}rem`,
          animationDuration: `${duration}s`,
        }}
      >
        {characters.map((character, i) => {
          const angle = (360 / characters.length) * i;
          return (
            <span
              style={{
                transform: `rotate(${-angle}deg)`,
                lineHeight: `${diameter}rem`,
              }}
              key={i}
            >
              {character}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default CircularText;
