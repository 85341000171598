import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

import Home from "./pages/Home";
import ITConsulting from "./pages/ITConsulting";
import Retail from "./pages/Retail";
import Design from "./pages/Design";
import ContactUs from "./pages/ContactUs";
// import { HeroHeightContext } from "./components/common/HeroHeightContext";
import { HeroHeightProvider } from './components/common/HeroHeightContext';
// import Blog from "./pages/Blog";

import "./App.scss";
import BlogList from "./components/BlogPage/BlogList";
import BlogDetail from "./components/BlogPage/BlogDetail";


const App: React.FC = () => {
  
  const ServiceLinks = [
    { to: "/retail", labelJa: "物販", labelEn: "Merchandise" },
    { to: "/design", labelJa: "デザイン", labelEn: "Graphic Design" },
    { to: "/it-consulting", labelJa: "ITコンサルティング", labelEn: "IT Consulting" },
    { to: "/contactus", labelJa: "お問い合わせ", labelEn: "Contact Us" },
  ];

  return (
    <HeroHeightProvider>
      <Router>
        <Header links={ServiceLinks} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/it-consulting" element={<ITConsulting />} />
          <Route path="/retail" element={<Retail />} />
          <Route path="/design" element={<Design />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/posts" element={<BlogList />} />
          <Route path="/posts/:id" element={<BlogDetail />} />
          <Route path="/posts/tags/:tagSlug" element={<BlogList />} />
        </Routes>
        <Footer links={ServiceLinks}/>
      </Router>
    </HeroHeightProvider>
  );
};

export default App;