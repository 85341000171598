import React from 'react';
import './BusinessOverview.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Navigation, Pagination, Parallax } from 'swiper';

interface BusinessDetails {
  titleJapanese: string;
  titleEnglish: string;
  description: string;
  imageSrc: string;
}

interface BusinessOverviewProps {
  businessDetails: BusinessDetails[];
}

const BusinessOverview: React.FC<BusinessOverviewProps> = ({ businessDetails }) => {
  return (
    <section className="business-overview">
      <h2 className="h2-title">
        <div className="english">Our Business</div>
        <div className="japanese">我々のビジネス</div>
      </h2>
      {/* <Container className="items"> */}
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        parallax={true}
        autoplay={{
          delay: 15000,
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[Parallax, Keyboard, Pagination, Navigation, Autoplay]}
      >
          {/* <div
            slot="container-start"
            className="parallax-bg"
            style={{
              // backgroundImage: "url(/images/section/retail_japanese_texture_03.jpg)",
              // borderRadius: "5rem"
            }}
            data-swiper-parallax="-23%"
          ></div> */}

          {businessDetails.map((detail, index) => (
            <SwiperSlide
              key={`business-overview-${index}`}
              className={
                `item-wrap ${index % 2 === 0 ? "item-wrap-even" : "item-wrap-odd"}`
              }
            >
              <div
                data-swiper-parallax="-23%"
                style={{
                  backgroundImage: `url(${detail.imageSrc})`,
                }}
                className="item-image"  
              />
              <div className="item-title" data-swiper-parallax="-100">
                {detail.titleJapanese}
              </div>
              <div className="item-title-english" data-swiper-parallax="-200">
                {detail.titleEnglish}
              </div>
              <div className="item-description" data-swiper-parallax="-300">
                {detail.description.split('/').map((text,index) => 
                  <p
                    key={`item-description-text-${index}`}
                    data-swiper-parallax={-150*(index+1)}
                  >{text}</p>
                )}
              </div>
            </SwiperSlide>
          ))}


        </Swiper>
      {/* </Container> */}

      {/* <Container>
        <Row className="items">
          {businessDetails.map((detail, index) => (
            <Col key={index} lg={4} className="item-wrap">
              <h3 className="h3-title">
                <span className="japanese">{detail.titleJapanese}</span>
                <span className="english">{detail.titleEnglish}</span>
              </h3>
              <Image src={detail.imageSrc} alt={detail.titleEnglish} className="item-image"/>
              <p className="p1">{detail.description}</p>
            </Col>
          ))}
        </Row>
      </Container> */}
      
    </section>
  );
};

export default BusinessOverview;
