import React from 'react';
import ImageWithDescription from '../common/ImageWithDescription';
import './OurStrengths.scss';

const OurStrengths: React.FC = () => {
  const strengthsData = [
    {
      title: '豊富な経験',
      content: '我々のチームは、DTC、PwCなどの有名企業での多年にわたる経験を持っています。この経験を通じて、我々は幅広い業界のITコンサルティングに関する深い知識と専門技術を身につけてきました。',
      image: '/images/section/itconsulting_strength_01.jpeg',
      btn: {
        label: "詳しく見る",
        path: "/posts/tags/事例",
      },
    },
    {
      title: '独自のメソドロジー',
      content: 'ファームでの経験と個人・法人として様々な案件を扱う中で、独自のメソドロジーを開発しました。これは、一般的なアプローチでは見落とされがちなユニークな視点を提供し、顧客の問題解決に対する我々のアプローチを強化します。',
      image: '/images/section/itconsulting_strength_02.jpeg',
      btn: {
        label: "詳しく見る",
        path: "/posts/tags/メソドロジー",
      },
    },
    {
      title: '深い技術的知見',
      content: '我々は最新のテクノロジーと業界の動向を把握し続けています。この知見の深さにより、我々は顧客に対して最適な解決策を提供することが可能です。',
      image: '/images/section/itconsulting_strength_03.jpeg',
    },
    {
      title: '高い費用対効果',
      content: '少人数のチームで対応することにより、我々はスピーディーなサービス提供を可能にします。これにより、迅速な対応、アジリティ、そしてコスト効率の高さが我々のサービスの特徴となっています。',
      image: '/images/section/itconsulting_strength_04.jpeg',
      btn: {
        label: "詳しく見る",
        path: "/posts/understanding-consulting-fees-to-maximize-consultancy-utilization",
      },
    },
  ];
  
  return (
    <section className="it-consulting-our-strengths">
      <h2 className="h2-title">
        <div className="english">Our Strengths</div>
        <div className="japanese">私たちの強み</div>
      </h2>
      <ImageWithDescription 
        data = {strengthsData}
        rowHeight={20}
        imageToTextRatio={[4,8]}
      />
    </section>
  );
};

export default OurStrengths;
