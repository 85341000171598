import Hero from '../common/Hero';
import { HeroType } from '../../types/Hero';
import ScrollingTransparentSquare from '../common/ScrollingTransparentSquare';
import { HeroHeightContext } from '../common/HeroHeightContext';
import { useCallback, useContext, useEffect } from "react";
import './HeroSection.scss';

interface HeroSectionProps{
  hero: HeroType;
}

const HeroSection: React.FC<HeroSectionProps> = ({hero}) => {
  const { setHeroHeight, heroHeight } = useContext(HeroHeightContext);

  const handleResize = useCallback(() => {
    const width = Math.max(Math.min(window.innerWidth,1320),320);
    const newBoxHeight = Math.max(0.0001 * width * width - 0.227 * width + 175, 47); // 画面幅に応じた高さを計算
    setHeroHeight.vw(newBoxHeight);
  ;
  }, [setHeroHeight]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
  
  return (
    <section className="design-hero position-relative" style={{overflow: `hidden`}}>
      <Hero hero={hero} height={`${heroHeight.vw}vw`}/>
      <ScrollingTransparentSquare
          top={{initial:0, end:0}}
          bottom={{initial:25, end:5}}
          left={{initial:30, end:15}}
          right={{initial:0, end:0}}
          backgroundColor='rgba(92, 146, 145, 1)'
          boxHeight={heroHeight.vw}
          windowWidth={window.innerWidth}
      />
      <ScrollingTransparentSquare
          top={{initial:0, end:0}}
          bottom={{initial:0, end:0}}
          left={{initial:0, end:0}}
          right={{initial:70, end:85}}
          backgroundColor='rgba(192, 210, 121, 1)'
          boxHeight={heroHeight.vw}
          windowWidth={window.innerWidth}
      />
      <ScrollingTransparentSquare
          top={{initial:75, end:95}}
          bottom={{initial:0, end:0}}
          left={{initial:0, end:0}}
          right={{initial:0, end:0}}
          backgroundColor='rgba(239, 214, 89, 1)'
          boxHeight={heroHeight.vw}
          windowWidth={window.innerWidth}
      />
    </section>
  );
};

export default HeroSection;
