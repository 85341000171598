import React from 'react';
import { Modal, Button, Image } from 'react-bootstrap';
import { Case } from '../../types/Case';

interface DetailModalProps {
  caseItem: Case | null;
  show: boolean;
  onClose: () => void;
}

const DetailModal: React.FC<DetailModalProps> = ({ caseItem, show, onClose }) => {
  if (!caseItem) {
    return null; // もしくは、適切な代替コンテンツを表示するなど、null の場合の処理を行います
  }
  
  return (
    <Modal show={show} onHide={onClose} fullscreen="md-down">
      <Modal.Header closeButton>
        <Modal.Title>{caseItem.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Image src={caseItem.image.data as string} alt={caseItem.title} style={{width:`100%`, aspectRatio: `16/9`, objectFit: `cover`}} />
        <p>タグ： {caseItem.tags.join(", ")}</p>
        <p>{caseItem?.description}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DetailModal;
