import React from 'react';
import './UnderConstruction.scss';
import { Container } from 'react-bootstrap';

interface UnderConstructionProps {
  text: string;
}

const UnderConstruction: React.FC<UnderConstructionProps> = ({ text }) => {
  return (
    <Container className="under-construction">
      <div className="d-flex justify-content-center my-auto">
        {text}
        <span className="dots-animation-1">.</span>
        <span className="dots-animation-2">.</span>
        <span className="dots-animation-3">.</span>
      </div>
    </Container>
  );
};

export default UnderConstruction;
