import { useEffect, useState } from 'react';
import './ScrollingTransparentSquare.scss';

interface SquareProps {
  top: {initial:number; end:number};
  bottom: {initial:number; end:number};
  left: {initial:number; end:number};
  right: {initial:number; end:number};
  backgroundColor: string;
  boxHeight: number;
  windowWidth: number;
}

const ScrollingTransparentSquare: React.FC<SquareProps> = ({
  top, bottom, left, right, backgroundColor, boxHeight, windowWidth,
}) => {

  const [style, setStyle] = useState({
    top: `${top.initial}vh`,
    left: `${left.initial}vw`,
    right: `${right.initial}vw`,
    bottom: `${bottom.initial}vh`,
    backgroundColor,
  });

  useEffect(() => {
    const handleScroll = () => {
      const heightRatio = boxHeight / 100;
      const scrollRatio = document.documentElement.scrollTop /(windowWidth * heightRatio);

      setStyle({
        top: `${(top.initial + (top.end - top.initial) * scrollRatio) * heightRatio}vw`,
        left: `${left.initial + (left.end - left.initial) * scrollRatio}vw`,
        right: `${right.initial + (right.end - right.initial) * scrollRatio}vw`,
        bottom: `${(bottom.initial + (bottom.end - bottom.initial) * scrollRatio) * heightRatio}vw`,
        backgroundColor,
      });
    }

    handleScroll(); // 初回レンダリング時にスクロールハンドラを実行

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [top, left, right, bottom, backgroundColor, boxHeight, windowWidth]);

  return (
    <div className="scrolling-square" style={style} />
  );
};

export default ScrollingTransparentSquare;
