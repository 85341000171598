import React from 'react';
// import { Container, Row, Col, Image, Button } from 'react-bootstrap';
// import ImageWithDescription from '../common/ImageWithDescription';
import ImageWithDescription from '../common/ImageWithDescription2';
import './OurStrengths.scss';

interface StrengthProps {
  strengthsData: Array<{
    title: string,
    content: string,
    image: string,
    btn?: {
      path: string,
      label: string
    }
  }>
}

const Strengths: React.FC<StrengthProps> = ({ strengthsData }) => {

  return (
    <section className="our-strengths">
      <h2 className="h2-title">
        <div className="english">Our Unique Strengths</div>
        <div className="japanese">我々独自の強み</div>
      </h2>
      {/* <ImageWithDescription 
        data = {strengthsData}
        rowHeight={20}
        imageToTextRatio={[4,8]}
      /> */}
      <ImageWithDescription 
        data = {strengthsData}
        rowHeight={20}
        imageRatio={50}
      />
    </section>
  );
};

export default Strengths;
