import Contact from '../components/common/Contact';
import Hero from '../components/common/Hero';
import { HeroHeightContext } from '../components/common/HeroHeightContext';
import { BREAKPOINT_LG } from '../styles/constants';
import { HeroType } from '../types/Hero';
import { useCallback, useContext, useEffect } from 'react';



const ContactUs: React.FC = () => {
  const { setHeroHeight, heroHeight } = useContext(HeroHeightContext);

  const hero: HeroType = {
    imageUrl : 'images/section/contact_us.jpg',
    overlayColor: 'rgba(40, 40, 40, 1)'
  };
  
  const adjustHeroHeight = useCallback(() => {
    if (window.innerWidth < BREAKPOINT_LG ) {
      setHeroHeight.rem(9.5);
    } else {
      setHeroHeight.rem(7);
    }
  }, [setHeroHeight]);
  
  useEffect(() => {
    adjustHeroHeight(); // 初期値を設定
    window.addEventListener('resize', adjustHeroHeight); // ウィンドウのサイズが変わったときに再計算
    return () => {
      window.removeEventListener('resize', adjustHeroHeight); // アンマウント時にリスナーを削除
    }
  }, [adjustHeroHeight]);
  

  return (
    <>
      <Hero hero={hero} height={`${heroHeight.rem}rem`} />
      <Contact title="お問い合わせ" displayForm={true} displayLineRegistration={true}/>
    </>
  );
};

export default ContactUs;