import React from 'react';
// import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Keyboard, Navigation, Pagination, Autoplay } from 'swiper';

//type
import { Case } from '../../types/Case';

import 'swiper/swiper.min.css'

// import DetailModal from '../common/DetailModal';
// import ImageOverlayCard from '../common/ImageOverlayCard';
import UnderConstruction from '../common/UnderConstruction';
import './CasesSection.scss';

interface CaseSectionProps {
  cases: Case[];
}

const CasesSection: React.FC<CaseSectionProps> = ({cases}) => {
  // const [showModal, setShowModal] = useState(false);
  // const [currentCase, setCurrentCase] = useState<Case | null>(null);

  // const handleShowDetail = (itcase: Case) => {
  //   setCurrentCase(itcase);
  //   setShowModal(true);
  // };

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  return (
    <section className="it-consulting-cases">
      <h2 className="h2-title">
        <div className="english">Our Projects</div>
        <div className="japanese">事例紹介</div>
      </h2>
      <Container className="mb-5 px-0">
        <UnderConstruction text="Under Construction"/>
      {/* <Swiper
        // slidesPerView={1}
        spaceBetween={30}
        loop={true}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        navigation={true}
        modules={[Keyboard, Pagination, Navigation, Autoplay]}
      >
        {cases.map((itcase: Case, index: number) => (
          <SwiperSlide key={index}>
            <ImageOverlayCard caseItem={itcase} handleShowDetail={handleShowDetail} />
          </SwiperSlide>
        ))}
      </Swiper>
      <DetailModal show={showModal} caseItem={currentCase} onClose={handleCloseModal} /> */}
      </Container>
    </section>
  );
};

export default CasesSection;
