import React from 'react';
import HeroSection from '../components/ITConsultingPage/HeroSection';
import OurCapability from '../components/ITConsultingPage/OurCapability';
import OurStrengths from '../components/ITConsultingPage/OurStrengths';
import CasesSection from '../components/ITConsultingPage/CasesSection';
import Contact from '../components/common/Contact';
import { HeroType } from '../types/Hero';
import './ITConsulting.scss';

// svgs
import { ReactComponent as Case1Image } from '../components/assets/svg/itconsulting_case_01.svg';

const servicesData = [
  {
    title: "DX企画/システム企画",
    content: [
      {
        subtitle:'新規ビジネス創出・アプリ開発支援',
        text:'デジタル変革の波を掴み、競争力を強化します。私たちの専門知識と経験を活かして、新規ビジネスの創出からアプリ開発まで全体的にサポートします（攻めのIT）。'
      },
      {
        subtitle:'システムによる業務効率化',
        text:'業務の煩雑さを解消し、生産性を高めます。現状の業務フローを詳細に分析し、最適なシステムを導入・運用して業務効率を向上させます（守りのIT）。'
      }
      ],
      image: "/images/section/itconsulting_itplanning.jpeg",
  },
  {
    title: '人材育成支援',
    content: [
      {
        subtitle:'講義形式の人材育成',
        text:'ITスキルを磨きたい全ての人に対して、理論と実践を組み合わせた講義を提供します（OFF JT）。'
      },
      {
        subtitle:'プロジェクト支援による担当者教育',
        text:'講師が現役コンサルであるため、現場のリアルな課題を通じたスキル向上も支援可能です（OJT）。'
      }
    ],
    image: "/images/section/itconsulting_hrdevelopment.jpeg",
  },
  {
    title: 'プロジェクト管理支援',
    content: '当社はITを活用した効率化、海外展開に関する豊富な経験があります。私たちはお客様のビジネスを成功に導くために、常に最新の情報や技術を提供していきます。',
    image: "/images/section/itconsulting_pjmanagement.jpeg",
  },
]

const tempImage = "https://via.placeholder.com/1500x800"
const cases = [
  {
    image: {
      type: 'svg' as const,
      data: <Case1Image />,
    },
    title: "大手商社における新規事業立ち上げアプリ開発支援",
    tags: ["新規事業", "アプリ開発", "ビジネス戦略"],
    description:
      "新規事業の立ち上げは、ターゲット市場への新規参入や、新たなビジネスモデルの構築を伴うことが多く、それに伴うデジタルツールの開発は不可欠です。大手商社における新規事業立ち上げアプリ開発支援では、事業計画からユーザー要求までを踏まえたアプリの設計・開発を行います。そのためには、技術的な視点だけでなく、ビジネス戦略に深い理解をもって対応することが求められます。"
  },
  {
    image: {
      type: 'jpeg' as const,
      data: tempImage,
    },
    title: "大手サービス企業における海外支社マネジメント支援",
    tags: ["海外進出", "マネジメント", "ビジネス環境"],
    description:
      "大手サービス企業の海外進出は、多くの課題を伴います。異なる法制度、文化、市場状況などを理解し、適切に対応するための支援が求められます。そのためには、地元のビジネス環境に対する深い理解や、現地スタッフとの円滑なコミュニケーションが不可欠です。海外支社マネジメント支援では、そのような課題を解決するための戦略策定から実行までをサポートします。"
  },
  {
    image: {
      type: 'jpeg' as const,
      data: tempImage,
    },
    title: "日本進出企業における社内IT管理・開発管理支援",
    tags: ["日本進出", "IT管理", "開発管理"],
    description:
      "日本市場への進出は、特にIT面での調整が必要です。ソフトウェアのローカライゼーション、データプライバシー法規制の遵守、そして日本独自のビジネス習慣への適応などが求められます。社内IT管理・開発管理支援では、これらの課題を技術的に解決するだけでなく、日本市場に適したIT戦略の立案と実行を支援します。"
  },
  {
    image: {
      type: 'jpeg' as const,
      data: tempImage,
    },
    title: "AI活用のための教育支援",
    tags: ["AI", "教育", "技術理解"],
    description:
      "AI技術は現代社会における重要な技術であり、その活用が求められています。しかし、AIを適切に活用するためには、その基本原理から応用までを理解することが必要です。そのための教育支援では、AIの基本原理から、具体的な活用方法までを学ぶプログラムを提供します。"
  },
];

const ITConsulting: React.FC = () => {

  const hero: HeroType = {
    mainMessage : "あなたのビジネスを/次のレベルへ",
    imageUrl : "/images/section/itconsulting_hero.jpeg",
    imageIcon: "/images/section/itconsulting_icon.svg",
    // overlayColor: 'rgba(120,120,120,1)',
  };

  return (
    <>
      <HeroSection hero={hero}/>
      <OurCapability servicesData={servicesData}/>
      <OurStrengths />
      <CasesSection cases={cases}/>
      <section className="it-consulting-contact">
        <Contact title="お問い合わせ" displayForm={true} displayLineRegistration={false}/>
      </section>
    </>
  );
};

export default ITConsulting;
