import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Image, Container} from 'react-bootstrap';
import './ImageWithDescription.scss';
import { useNavigate } from 'react-router-dom';


interface SubContent {
  subtitle: string;
  text: string;
}

interface Item {
  title: string;
  content: string | SubContent[];
  image: string | React.ReactNode; // imageをReactNode（Reactコンポーネント）も受け入れるように修正
  btn?: {
    path: string;
    label: string;
  };
}

interface ImageWithDescriptionProps {
  data: Item[];
  rowHeight: number;
  imageToTextRatio?: [number, number];
  verticalMargin?: number;
  alternateLayout?: boolean;
}

const ImageWithDescription: React.FC<ImageWithDescriptionProps> = ({ data, rowHeight, imageToTextRatio = [5, 7], verticalMargin = 2, alternateLayout = true }) => {
  const navigate = useNavigate();
  const handleClick = (path: string) => {
    if (path.startsWith('http://') || path.startsWith('https://')) {
      // 外部リンクの場合は新しいタブで開く
      window.open(path, '_blank', 'noopener,noreferrer');
    } else {
      // 内部リンクの場合はreact-router-domのnavigateを使用する
      navigate(path);
      window.scrollTo(0, 0);
    }
  };

  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  const imageRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    imageRefs.current = imageRefs.current.slice(0, data.length);
    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('zoom');
          observer && observer.unobserve(entry.target);
        }
      });
    };
    const observerOptions = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0
    };
    const observer = new IntersectionObserver(callback, observerOptions);
    imageRefs.current.forEach((ref) => ref && observer.observe(ref));
    setObserver(observer);
  }, [data]);


  useEffect(() => {
    return () => {
      observer && observer.disconnect();
    };
  }, [observer]);
  
  return (
    <div className="image-with-description">
      {data.map((item, index) => {
        const isAlternate = alternateLayout ? index % 2 === 0 : true;
        const btn = item.btn;

        return (
          <div key={index} className="detail" style={{ height: `${rowHeight}rem`, backgroundColor: index % 2 === 0 ? '#fff' : '#f8f8f8' }}>
            <Container>
              <Row className={`${isAlternate ? 'even' : 'odd'} align-items-center`} >
                <Col xs={12} lg={{ span: imageToTextRatio[1], order: isAlternate ? 1 : 2 }} className="detail-text">
                  <h3 className="h3-title">{item.title}</h3>
                  {Array.isArray(item.content) ? (
                    item.content.map((contentItem, contentIndex) => (
                      <div key={contentIndex} className="detail-text">
                        <h4 className="h4-title">{contentItem.subtitle}</h4>
                        <p className="p1">{contentItem.text}</p>
                      </div>
                    ))
                  ) : (
                    <p className="p1">{item.content}</p>
                  )}
                  {btn && (  // ここで一時変数を使います
                    <p
                      onClick={() => handleClick(btn.path)} className="detail-button btn btn-light">
                      {btn.label}
                    </p>
                  )}
                </Col>
                <Col xs={12} lg={{ span: imageToTextRatio[0], order: isAlternate ? 2 : 1 }} >
                  <div className="detail-image-wrap" style={{ height: `${rowHeight-verticalMargin*2}rem`, margin: `${verticalMargin}rem 0`}}>
                {
                  typeof item.image === "string" ?
                  <Image
                    src={item.image}
                    alt={item.title}
                    className="detail-image"
                    ref={el => imageRefs.current[index] = el} //for animation
                    />
                  :
                  <div
                    className="detail-image"
                    ref={el => imageRefs.current[index] = el} //for animation
                    >
                    {item.image}
                  </div>
                }
                  </div>
                </Col>

              </Row>
              </Container>
          </div>
        );

      })}
    </div>
  );
}

export default ImageWithDescription;