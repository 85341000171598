// src/components/BlogDetail.tsx
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { BlogPost } from '../../types/BlogPost';
import { Col, Container, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { marked } from 'marked';
import Hero from '../common/Hero';
import { HeroType } from '../../types/Hero';
import { HeroHeightContext } from '../common/HeroHeightContext';
import './BlogDetail.scss'
import { BREAKPOINT_LG } from '../../styles/constants';
import BlogTile from './BlogTile';
import BlogTag from './BlogTag';
import { SplitBySlash } from '../common/Utils';

type BlogDetailParams = {
  id: string;
};

interface IHeading {
  id: string;
  text: string;
  level: string;
}


const BlogDetail: React.FC = () => {
  const { heroHeight, setHeroHeight } = useContext(HeroHeightContext);

  const { id } = useParams<BlogDetailParams>();
  const [post, setPost] = useState<BlogPost|null>(null);
  const [content, setContent] = useState<string>("");
  const [relatedPosts, setRelatedPosts] = useState<BlogPost[]>([]);

  const [headings, setHeadings] = useState<IHeading[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);

  const markdownContentRef = useRef<HTMLDivElement>(null);
  const headingElementsRef = useRef<HTMLHeadingElement[]>([]);
  // const [observer, setObserver] = useState<IntersectionObserver | null>(null);

  const generateId = useCallback((tag: string, index: number): string => {
    const sanitizedTag = tag.replace(/[^a-z0-9]/gi, ""); // タグ名から非英数字を削除
    return `heading-${sanitizedTag}-${index}`;
  }, []);

  const generateTOC = useCallback((markdown: string) => {
    const tokens = marked.lexer(markdown);
    const headings: IHeading[] = [];
    
    let count: number = 0;
    tokens.forEach((token) => {
      if (token.type === 'heading') {
        count += 1;
        const level = `h${token.depth}`;
        const id = generateId(level.toLowerCase(), count);
        const text = token.text;
        headings.push({ id, text, level });
      }
    });
    return headings;
  }, [generateId]);

  useEffect(() => {
    if (!markdownContentRef.current) {
      return;
    }

    headingElementsRef.current = Array.from(
      markdownContentRef.current.querySelectorAll("h1, h2, h3, h4, h5, h6")
    );

    const observerOptions = {
      root: null,
      rootMargin: "0% 0% -95% 0%",
      threshold: 0.1 
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log(entry.target.id);
          setActiveId(entry.target.id); 
        }
      });
    }, observerOptions);

    headingElementsRef.current.forEach((element, index) => {
      const id = generateId(element.tagName.toLowerCase(), index + 1);
      element.id = id;
      console.log('element',element.tagName.toLowerCase(), id, index);
      observer.observe(element);
    });

    return () => {
      headingElementsRef.current.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, [generateId, headings]);

  const handleHeadingClick = (id: string) => {
    const element = headingElementsRef.current.find((el) => el.id === id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

const fetchContent = async (filePath: string): Promise<string> => {
  const response = await fetch(filePath);
  const content = await response.text();
  return content;
};

useEffect(() => {
  const rootDir = '/data'
  fetch(`${rootDir}/posts.json`)
    .then(response => response.json())
    .then(async data => {
      const foundPost = data.find((p: BlogPost) => p.id ===id);
      setPost(foundPost);
      if (foundPost) {
        let content = "";
        if (foundPost.mdpath) {
          content = await fetchContent(`${rootDir}/${foundPost.mdpath}`);
        } else if  (foundPost.content) {
          content = foundPost.content
        }
        setContent(content);
        setHeadings(generateTOC(content)); // create table of contents
        const related = data
        .filter((p: BlogPost) => p.id !== foundPost.id && foundPost.tags.some((tag: string) => p.tags.includes(tag)))
        .sort((a: BlogPost, b: BlogPost) => Date.parse(b.date) - Date.parse(a.date))
        .slice(0, 5);
        setRelatedPosts(related)
      }
    })}, [id, generateTOC, content, setContent]);
  

    const adjustHeroHeight = useCallback(() => {
      if (window.innerWidth < BREAKPOINT_LG ) {
        setHeroHeight.rem(9.5);
      } else {
        setHeroHeight.rem(7);
      }
    }, [setHeroHeight]);
    
    useEffect(() => {
      adjustHeroHeight(); // 初期値を設定
      window.addEventListener('resize', adjustHeroHeight); // ウィンドウのサイズが変わったときに再計算
      return () => {
        window.removeEventListener('resize', adjustHeroHeight); // アンマウント時にリスナーを削除
      }
    }, [adjustHeroHeight]);    


  if (!post) {
    return <div>Loading...</div>;
  }

  const hero: HeroType = {
    imageUrl: "/images/section/blog_hero.jpg",
    overlayColor: 'rgba(92, 146, 145, 1)'
  };


  return (
    <div className='blog-detail'>
      <Hero hero={hero} height={`${heroHeight.rem}rem`}/>
      <Container className="my-5">
        <Row className="blog-content">

          <Col lg={9}>
            <div className="eyecatch-wrap">
              <img src={post.eyecatch} alt={post.title} className="img"/>
              <div className="title">
                <SplitBySlash input={post.title} />
              </div>
            </div>
            {post.tags.map( (tag,index) => (
              <BlogTag key={index} tag={tag} to={tag} />
            ))}
            <p>Created at: {post.date}</p>
            <div ref={markdownContentRef} className="blog-body">
              <ReactMarkdown>{content}</ReactMarkdown>
            </div>
          </Col>

          <Col lg={3}>
            <div className='table-of-content d-none d-lg-block'>
              <h3 className="h3-title">目次</h3>
              <div className="table-of-content">
              {headings.map((heading) => (
                <p
                  key={heading.id}
                  onClick={() => handleHeadingClick(heading.id)}
                  className={`${heading.level} ${heading.id === activeId ? "active" : ""}`}
                >
                  {heading.text}
                </p>
              ))}
              </div>
            </div>
          </Col>

        </Row>

        {relatedPosts.length > 0 && (
          <Row>
            <h2 className="h2-title">Related Posts</h2>
            {relatedPosts.slice(0,3).map(relatedPost => (
              <Col key={relatedPost.id} xs={6} lg={4}>
                <BlogTile post={relatedPost} />
              </Col>
            ))}
          </Row>
        )}

      </Container>
    </div>
  );
};

export default BlogDetail;
