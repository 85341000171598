import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Members.scss";

type Member = {
  id: number;
  englishName: string;
  japaneseName: string;
  position: string;
  title: string;
  introduction: string;
  image: string;
};

type MembersProps = {
  members: Member[];
};

const Members: React.FC<MembersProps> = ({ members }) => {

  function replaceWithLineBreaks(str: string, target: string, classes?: string[]) {
    const parts = str.split(target);
  
    if (classes && parts.length !== classes.length) {
      throw new Error("The number of classes should match the number of split parts.");
    }
  
    const elements = parts.map((part, index) => {
      if (classes) {
        return <div className={classes[index]} key={index}>{part}</div>;
      } else {
        return <div key={index}>{part}</div>;
      }
    });
  
    return <>{elements}</>;
  }
  
  return (
    <div className="members">
      <Row>
        <h2>MEMBER</h2>
      </Row>
      {members.map((member, index) => (
        <Row
          key={member.id}
          className={`justify-content-center ${index % 2 === 1 ? 'swap-sides' : ''}`}
        >
          <Col xs={12} sm={11} className="member-container">
            
            <div className={`left-side d-flex ${index % 2 === 1 ? 'order-md-2' : ''}`}>
              <div className='id'>
                MEMBER<span className="number">{member.id.toString().padStart(2, "0")}</span>
              </div>
              <div className="english-name">
                {replaceWithLineBreaks(member.englishName, '/', ["last","first"])}
              </div>
              <img className="img-fluid image" src={member.image} alt={member.englishName} />
            </div>

            <div className={`right-side d-flex align-items-center ${index % 2 === 1 ? 'order-md-1' : ''}`}>
              <div>
                <div className="title">
                  {replaceWithLineBreaks(member.title, '/')}
                </div>
                <p className="position">{member.position}</p>
                <p className="japanese-name">{member.japaneseName}</p>
                <p className="introduction">{member.introduction}</p>
              </div>
            </div>
          </Col>

        </Row>

      ))}
    </div>
  );
};

export default Members;
