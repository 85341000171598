import React, { useEffect, useState } from "react";
import { Row, Image, Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation } from 'swiper';
// import { Navigation } from 'swiper';
import 'swiper/swiper.min.css';
// import 'swiper/components/navigation/navigation.min.css';
import "./OurBusiness.scss";

type Service = {
  serviceNameJP: string;
  serviceNameEN: string;
  serviceDescription: string;
  serviceIcon: string;
};

type ServicesComponentProps = {
  services: Service[];
};

type ResponsiveContainerProps = {
  children: React.ReactNode;
};

const ResponsiveContainer: React.FC<ResponsiveContainerProps> = ({ children }) => {
  const [shouldUseFluid, setShouldUseFluid] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const shouldUseFluidValue = screenWidth <= 991;
      setShouldUseFluid(shouldUseFluidValue);
    };

    // 初回レンダリング時とウィンドウのリサイズ時に処理を実行
    handleResize();
    window.addEventListener("resize", handleResize);

    // コンポーネントのクリーンアップ時にイベントリスナーを削除
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <Container fluid={shouldUseFluid}>{children}</Container>;
};


const ServicesComponent: React.FC<ServicesComponentProps> = ({ services }) => {

  return (
    <ResponsiveContainer>
      <div className="our-business">
      <Row>
        <h2>OUR BUSINESS</h2>
      </Row>

      <Row>
        <Swiper
          spaceBetween={0}
          loop={true}
          loopPreventsSliding={true}
          keyboard={{
            enabled: true,
          }}
          centeredSlides={true}
          breakpoints={{
            320: {
              loop: true,
              loopPreventsSliding: true,
              slidesPerView: 1,
              allowSlidePrev: true,
              allowSlideNext: true,
              allowTouchMove: true,
              grabCursor: true,
              navigation: true,
              autoplay: {
                delay: 3500,
                disableOnInteraction: false,
              }
            },
            576: {
              loop: true,
              loopPreventsSliding: true,
              slidesPerView: 2,
              allowSlidePrev: true,
              allowSlideNext: true,
              allowTouchMove: true,
              grabCursor: true,
              navigation: true,
              autoplay: false
            },
            992: {
              loop: true,
              loopPreventsSliding: true,
              slidesPerView: 3,
              allowSlidePrev: false,
              allowSlideNext: false,
              allowTouchMove: false,
              grabCursor: false,
              navigation: false,
              autoplay: false
            },
          }}
          modules={[Keyboard, Navigation]}
        >
          {[0, 1].map((slideIndex) =>
            services.map((service, index) => (
              <SwiperSlide key={`${service.serviceNameEN}-${slideIndex}-${index}`}>
                <div className="service">
                  <Image className="mx-auto d-block icon" src={service.serviceIcon} />
                  <h3>
                    <div className="ja">{service.serviceNameJP}</div>
                    <div className="en">{service.serviceNameEN}</div>
                  </h3>
                  <p>{service.serviceDescription}</p>
                </div>
              </SwiperSlide>
            ))
          )}
      </Swiper>
    </Row>
    </div>
    </ResponsiveContainer>
  );
};

export default ServicesComponent;