import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { BlogPost } from '../../types/BlogPost';
import { Pagination, Container, Row, Col } from 'react-bootstrap';

import Hero from '../common/Hero';
import {HeroType} from '../../types/Hero';
import { HeroHeightContext } from '../common/HeroHeightContext';
import { useParams } from 'react-router-dom';
import { BREAKPOINT_LG } from '../../styles/constants';
import BlogTile from './BlogTile';
import BlogTag from './BlogTag';

type BlogTagParams = {
  tagSlug: string;
};

const hero: HeroType = {
  imageUrl: "/images/section/blog_hero.jpg",
  overlayColor: 'rgba(92, 146, 145, 1)'
}

const BlogList: React.FC = () => {
  const postsPerPage: number = 12;
  const { setHeroHeight, heroHeight } = useContext(HeroHeightContext);
  const { tagSlug } = useParams<BlogTagParams>();

  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [currentTag, setCurrentTag] = useState<string|null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // 全ての記事から一意なタグの一覧を取得する関数
  const getUniqueTags = (posts: BlogPost[]): string[] => {
    const tags = posts.reduce((acc, post) => [...acc, ...post.tags], [] as string[]);
    return Array.from(new Set(tags));
  };

  const uniqueTags = useMemo(() => getUniqueTags(posts), [posts]);

  useEffect(() => {
    if (tagSlug) {
      setCurrentTag(tagSlug);
    } else {
      setCurrentTag(null);
    }
  },[setCurrentTag, tagSlug])

  // データをJSONファイルからロードする
  useEffect(() => {
    fetch('/data/posts.json')
      .then(response => response.json())
      .then(data => {
        setPosts(data.sort((a: BlogPost, b: BlogPost) => Date.parse(b.date) - Date.parse(a.date)))
      });
  }, []);
  
  const adjustHeroHeight = useCallback(() => {
    if (window.innerWidth < BREAKPOINT_LG ) {
      setHeroHeight.rem(9.5);
    } else {
      setHeroHeight.rem(7);
    }
  }, [setHeroHeight]);
  
  useEffect(() => {
    adjustHeroHeight(); // 初期値を設定
    window.addEventListener('resize', adjustHeroHeight); // ウィンドウのサイズが変わったときに再計算
    return () => {
      window.removeEventListener('resize', adjustHeroHeight); // アンマウント時にリスナーを削除
    }
  }, [adjustHeroHeight]);
  

  
  // 現在のページの記事をフィルタリングし、ソートする
  const filteredPosts = useMemo(() => 
    posts
      .filter(post => currentTag == null || post.tags.includes(currentTag))
  , [posts, currentTag]);
  
  return (
    <>
    <Hero hero={hero} height={`${heroHeight.vw}vw`}/>
    <h2 className="h2-title">
      <div className="english">Article List</div>
      <div className="japanese">記事一覧</div>
    </h2>
    <Container>

      { /* 省略された記事一覧の表示 */ }
      <Row>
      {filteredPosts.slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage).map(post => (
        <Col xs={6} lg={4} key={post.id}>
          <BlogTile post={post} />
        </Col>
      ))}
      </Row>

      { /* ページネーションの実装 */ }
      {filteredPosts.length > postsPerPage &&
        <Pagination className="justify-content-center pagination">
          {Array(Math.ceil(filteredPosts.length / postsPerPage)).fill(0).map((_, idx) => (
            <Pagination.Item key={idx+1} className="item" active={idx+1 === currentPage} onClick={() => {
              setCurrentPage(idx+1)
              window.scrollTo(0, 0)
              }}>
              {idx+1}
            </Pagination.Item>
          ))}
        </Pagination>
      }
    </Container>

    <h2 className="h2-title">
      <div className="english">Tag List</div>
      <div className="japanese">タグ一覧</div>
    </h2>
    <Container>
      <div>
        <BlogTag tag="All" to=""/>
        {uniqueTags.map(tag => (
          <BlogTag tag={tag} to={tag}/>
        ))}
      </div>
    </Container>
    </>
  );
};

export default BlogList;
