import React from "react";
import { Container } from "react-bootstrap";
import HeroSection from '../components/HomePage/HeroSection';
// import VerticalLine from '../components/common/VerticalLine';
// import BusinessAreaSlider from "../components/HomePage/BusinessAreaSlider";
import "./Home.scss";
import OurBusiness from "../components/HomePage/OurBusiness";
import Members from "../components/HomePage/Members";
import { HeroType } from '../types/Hero';

// サービスのタイプ定義
type Service = {
  serviceNameJP: string;
  serviceNameEN: string;
  serviceDescription: string;
  serviceIcon: string;
};

type Member = {
  id: number;
  englishName: string;
  japaneseName: string;
  position: string;
  title: string;
  introduction: string;
  image: string;
};

const Home: React.FC = () => {

  const hero: HeroType = {
    imageUrl : "/images/background/home_hero_background.jpg"
  };

  // サンプルのサービス定義
  const services: Service[] = [
    {
      serviceNameJP: "物 販",
      serviceNameEN: "Merchandise",
      serviceDescription: "台湾で企業・消費者向けに高品質な商品を提供しています。要望に合わせて、独自のパッケージデザインや工場とも交渉しながら高品質な商品を販売しています。",
      serviceIcon: "/images/home/merchandise_icon.svg",
    },
    {
      serviceNameJP: "ITコンサルティング",
      serviceNameEN: "IT Consulting",
      serviceDescription: "ITを活用したビジネスの最適化・高度化・DX推進をサポートし、お客様の成果を最大化します。効率向上と競争力強化に貢献します。",
      serviceIcon: "/images/home/it_consulting_icon.svg",
    },
    {
      serviceNameJP: "デザイン",
      serviceNameEN: "Graphic Design",
      serviceDescription: "ビジネスからエンターテイメントまで、さまざまな領域のデザインニーズに対応しています。幅広いクリエイティブなソリューションを提供しています。",
      serviceIcon: "/images/home/graphic_design.svg",
    },
  ];

  const members: Member[] = [
    {
      id: 1,
      englishName: 'AIZAWA/YUTA',
      japaneseName: '相澤 雄太',
      position: 'JYU株式会社 代表取締役',
      title: 'IT Consultant',
      introduction: 'ビジネスとエンジニアリングの双方に精通し、金融や物流業界、AI活用・教育などの多様プロジェクトを多角的に支援してきた経歴を持つ。PwC、DTC出身。',
      image: "/images/home/member_01.jpg",
    },
    {
      id: 2,
      englishName: 'CHEN/PEI SYUAN',
      japaneseName: '陳 珮瑄',
      position: 'JYU株式会社 取締役',
      title: 'Art Director/Retail Manager',
      introduction: '鼎昇禮贈品(台湾法人)代表。EMBA保有。政府系組織・Youtuber・大手食品企業等向けに物販・デザインで10年以上の実績。法人としては30年の実績あり。',
      image: "/images/home/member_02.jpg",
    },
  ];

  return (
    <>
      <HeroSection hero={hero}/>
      {/* <BusinessAreaSlider slideData={slideData} /> */}

      {/* <VerticalLine
        color="rgba(40,40,40,0.5)"
        thickness="min(0.8rem, max(0.8vw, 0.3rem))"
        length="40vh"
        initialPositionRatio={{ x: 0.87, y: 0.797 }}
        scrollFactor={-0.15}
      />

      <VerticalLine
        color="rgba(120,120,120,0.5)"
        thickness="min(0.8rem, max(0.8vw, 0.3rem))"
        length="30vh"
        initialPositionRatio={{ x: 0.90, y: 0.845 }}
        scrollFactor={-0.1}
      />

      <VerticalLine
        color="rgba(60,60,60,0.5)"
        thickness="min(0.8rem, max(0.8vw, 0.3rem))"
        length="50vh"
        initialPositionRatio={{ x: 0.93, y: 0.75 }}
        scrollFactor={-0.2}
      /> */}

        <OurBusiness services={services}/>
        <Container>
          <Members members={members}/>
        </Container>
    </>
  );
};

export default Home;
