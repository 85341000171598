import React, { useEffect, useState } from "react";
import './Utils.scss';

interface HamburgerIconProps {
  isActive: boolean;
  heroHeight: number; //px
}

export const HamburgerIcon: React.FC<HamburgerIconProps> = ({ isActive, heroHeight }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > heroHeight - 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [heroHeight]);  

  return (
    <div 
      className={`hamburger-icon ${isActive ? 'active' : ''} ${scrolled ? 'scrolled' : ''}`} // scrolledクラスを追加
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};


// windowSize

interface Size {
  width: number;
  height: number;
}

export const useWindowSize = (): Size => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};



interface SplitBySplashProps {
  input: string;
}

export const SplitBySlash: React.FC<SplitBySplashProps> = ({ input }) => {
  const array = input.split('/');
  return (
    <>
      {array.map((item, index) => (
        <React.Fragment key={index}>
          {item}
          {index !== array.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );
}