import React from "react";
import HeroSection from '../components/DesignPage/HomeSection';
import { HeroType } from '../types/Hero';
import UnderConstruction from "../components/common/UnderConstruction";
import Portfolio from '../components/DesignPage/Portfolio';
// import FeaturesBenefits from '../components/DesignPage/ValueProposition';
// import OrderProcess from "../components/DesignPage/OrderProcess";
import Contact from '../components/common/Contact';

import "./Home.scss";
import { DesignCase } from '../types/Case';


// "私たちは、東洋の魅力とビジュアル体験を融合した日台デザインで新たな世界を創造するパッケージデザインサービスを提供しています。お客様のニーズを理解し、独自のアイデアと創造力を駆使して、魅力的で効果的なパッケージデザインを創り出します。",
// "私たちのプロセスは、ブリーフィングからリサーチ、コンセプト開発、デザイン実装、そして最終的な納品に至るまで、お客様と協力して進めていきます。これにより、お客様のビジョンを具現化し、製品の市場での認知度と競争力を高めることができます。",
// "私たちのチームは、日本と台湾の豊富な経験と専門知識を持つデザイナーたちで構成されており、幅広い業界と製品カテゴリーで実績を積んでいます。どのようなプロジェクトでも、私たちは最高品質のデザインとサービスを提供することに全力を尽くします。",
// "お問い合わせいただければ、お客様の製品が次のレベルへと進むお手伝いをさせていただきます。",
// ]

const designCases: DesignCase[] = [
  // {
  //   src: '/images/design/onepiece01_luffy.png',
  //   alt: 'Image 1',
  //   info: 'Image 1 description',
  //   caseItem: {
  //     image: {
  //       type:'jpeg',
  //       data:"https://via.placeholder.com/500x600",
  //     },
  //     title: "プロジェクト1",
  //     tags: ["デザイン", "フロントエンド"],
  //     description: "これはプロジェクト1の詳細な説明です。このプロジェクトでは、デザインとフロントエンドの技術を駆使して、ユーザーに優れた体験を提供するウェブサイトを開発しました。"
  //   }
  // },
  // {
  //   src: '/images/design/onepiece01_luffy2.png',
  //   alt: 'Image 2',
  //   info: 'Image 2 description',
  //   caseItem: {
  //     image: {
  //       type: 'jpeg',
  //       data:"https://via.placeholder.com/800x600",
  //     },
  //     title: "パッケージデザイン1",
  //     tags: ["パッケージデザイン", "ブランディング"],
  //     description: "これはパッケージデザイン1の詳細な説明です。このプロジェクトでは、ブランディングとパッケージデザインの専門知識を活用して、商品の価値を高め、消費者の関心を引くパッケージを作成しました。"
  //   }
  // },
  // {
  //   src: '/images/design/onepiece02_zoro_bandana.png',
  //   alt: 'Image 3',
  //   info: 'Image 3 description',
  //   caseItem: {
  //     image: {
  //       type: 'jpeg',
  //       data: "https://via.placeholder.com/800x800",
  //     },
  //     title: "パッケージデザイン2",
  //     tags: ["パッケージデザイン", "マーケティング"],
  //     description: "これはパッケージデザイン2の詳細な説明です。このプロジェクトでは、マーケティングとパッケージデザインの専門知識を組み合わせて、商品の市場での立ち位置を強化しました。"
  //   }
  // },
  // {
  //   src: '/images/design/onepiece02_zoro.png',
  //   alt: 'Image 4',
  //   info: 'Image 4 description',
  //   caseItem: {
  //     image: {
  //       type: 'jpeg',
  //       data: "/images/design/onepiece02_zoro.png",
  //     },
  //     title: "パッケージデザイン3",
  //     tags: ["パッケージデザイン", "エコデザイン"],
  //     description: "これはパッケージデザイン3の詳細な説明です。このプロジェクトでは、環境に優しいパッケージデザインを考案し、商品のサステナビリティを強調しました。"
  //   }
  // },
  // {
  //   src: '/images/design/onepiece03_nami.png',
  //   alt: "パッケージデザイン4",
  //   info: "これはパッケージデザイン4の短い説明です。",
  //   caseItem: {
  //     image: {
  //       type: 'jpeg',
  //       data: '/images/design/onepiece03_nami.png',
  //     },
  //     title: "パッケージデザイン4",
  //     tags: ["パッケージデザイン", "ラグジュアリー"],
  //     description: "これはパッケージデザイン4の詳細な説明です。このプロジェクトでは、ラグジュアリーなパッケージデザインを開発し、商品の高級感を引き立てました。"
  //   }
  // },
  // {
  //   src: '/images/design/onepiece04_usopp_sogeking.png',
  //   alt: "パッケージデザイン5",
  //   info: "これはパッケージデザイン5の短い説明です。",
  //   caseItem: {
  //     image: {
  //       type:'jpeg',
  //       data:'/images/design/onepiece04_usopp_sogeking.png',
  //     },
  //     title: "パッケージデザイン5",
  //     tags: ["パッケージデザイン", "ミニマリズム"],
  //     description: "これはパッケージデザイン5の詳細な説明です。このプロジェクトでは、ミニマリストのデザイン理念に基づき、シンプルで洗練されたパッケージデザインを追求しました。"
  //   }
  // },
  // {
  //   src: '/images/design/onepiece05_sanji.png',
  //   alt: "パッケージデザイン6",
  //   info: "これはパッケージデザイン6の短い説明です。",
  //   caseItem: {
  //     image: {
  //       type: 'jpeg',
  //       data: '/images/design/onepiece05_sanji.png',
  //     },
  //     title: "パッケージデザイン6",
  //     tags: ["パッケージデザイン", "ユーザビリティ"],
  //     description: "これはパッケージデザイン6の詳細な説明です。このプロジェクトでは、ユーザビリティを考慮したパッケージデザインを開発し、消費者の使用体験を向上させました。"
  //   }
  // },
  // {
  //   src: '/images/design/onepiece06_chopper.png',
  //   alt: "パッケージデザイン7",
  //   info: "これはパッケージデザイン7の短い説明です。",
  //   caseItem: {
  //     image: {
  //       type: 'jpeg',
  //       data: '/images/design/onepiece06_chopper.png',
  //     },
  //     title: "パッケージデザイン7",
  //     tags: ["パッケージデザイン", "リブランディング"],
  //     description: "これはパッケージデザイン7の詳細な説明です。このプロジェクトでは、既存の商品に新しい生命を吹き込むためのリブランディングを行い、パッケージデザインを一新しました。"
  //   }
  // },
  // {
  //   src: '/images/design/onepiece06_chopper2.png',
  //   alt: "パッケージデザイン8",
  //   info: "これはパッケージデザイン8の短い説明です。",
  //   caseItem: {
  //     image: {
  //       type: 'jpeg',
  //       data: '/images/design/onepiece06_chopper2.png',
  //     },
  //     title: "パッケージデザイン8",
  //     tags: ["パッケージデザイン", "イラストレーション"],
  //     description: "これはパッケージデザイン8の詳細な説明です。このプロジェクトでは、ユニークなイラストレーションを用いてパッケージデザインを鮮やかに彩り、消費者の注目を集めました。これはパッケージデザイン8の詳細な説明です。このプロジェクトでは、ユニークなイラストレーションを用いてパッケージデザインを鮮やかに彩り、消費者の注目を集めました。これはパッケージデザイン8の詳細な説明です。このプロジェクトでは、ユニークなイラストレーションを用いてパッケージデザインを鮮やかに彩り、消費者の注目を集めました。これはパッケージデザイン8の詳細な説明です。このプロジェクトでは、ユニークなイラストレーションを用いてパッケージデザインを鮮やかに彩り、消費者の注目を集めました。これはパッケージデザイン8の詳細な説明です。このプロジェクトでは、ユニークなイラストレーションを用いてパッケージデザインを鮮やかに彩り、消費者の注目を集めました。これはパッケージデザイン8の詳細な説明です。このプロジェクトでは、ユニークなイラストレーションを用いてパッケージデザインを鮮やかに彩り、消費者の注目を集めました。これはパッケージデザイン8の詳細な説明です。このプロジェクトでは、ユニークなイラストレーションを用いてパッケージデザインを鮮やかに彩り、消費者の注目を集めました。"
  //   }
  // },
];

const Design: React.FC = () => {

  const hero: HeroType = {
    imageUrl : "/images/section/design_hero.jpeg",
    imageIcon : "/images/section/design_icon.svg",
    mainMessage : "日台デザインの融合で/新たな世界を創造",
  };

  return (
    <div>
      <HeroSection hero={hero}/>
      <Portfolio designCases={designCases} />
      <UnderConstruction text="Under Construction"/>
      {/* <FeaturesBenefits /> */}
      {/* <OrderProcess /> */}
      <Contact
        title="お問い合わせ"
        description="下記より現在の検討内容をご共有ください。詳細をヒアリングさせていただき提案させていただきます。"
        displayForm={true}
        displayLineRegistration={true}/>
    </div>
  );
};


export default Design;
