import React, { useEffect, useState, useCallback, useContext } from "react";
import { HeroHeightContext } from '../common/HeroHeightContext';

import { Navbar, Nav, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { HamburgerIcon } from "../common/Utils";
import { Link } from '../../types/Link';
import './Header.scss';

interface LinksProps {
  links: Link[];
}

const Header: React.FC<LinksProps> = ({ links }) => {
  const { heroHeight } = useContext(HeroHeightContext);
  const [navExpanded, setNavExpanded] = useState(false);
  const [showMenuOverlay, setMenuOverlay] = useState(false);

  const handleScroll = useCallback(() => {
    setNavExpanded(false);
    setMenuOverlay(false);
  }, []);

  const handleMenu = (isVisible?: boolean) => {
    setNavExpanded((prevExpanded) => (isVisible !== undefined ? isVisible : !prevExpanded));
    setMenuOverlay((prevOverlay) => (isVisible !== undefined ? isVisible : Boolean(!prevOverlay)));
  };
  

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const firstHalfLinks = links.slice(0, Math.ceil(links.length / 2));
  const secondHalfLinks = links.slice(Math.ceil(links.length / 2));

  const NavLink: React.FC<{ links: Link[]; additionalClassName?: string }> = ({ links, additionalClassName }) => {
    return (
      <>
        {links.map((link) => (
          <Nav.Item key={link.to} className={`d-flex justify-content-center ${additionalClassName}`}>
            <LinkContainer
              to={link.to}
              className="menu-item menu-overlay"
              onClick={() => {
                handleMenu(false);
                window.scroll(0,0);
              }}
              >
              <div className="menu-jaen-wrap">
                <div className="ja">{link.labelJa}</div>
                <div className="en">{link.labelEn}</div>
              </div>
            </LinkContainer>
          </Nav.Item>
        ))}
      </>
    );
  };

  return (
    <div className="animated-header">
      <div className={`menu-background ${showMenuOverlay ? "active" : ""}`} onClick={() => handleMenu()} />

      {/* この部分はタブレット、スマフォサイズ時に表示 */}
      <LinkContainer to="/" className="d-block d-lg-none" onClick={() => handleMenu(false)} >
        <Image src="/images/header/corp_icon.svg" alt="JYU Toggle Icon" className="blandSp"/>
      </LinkContainer>
      
      <Navbar expand="lg" expanded={navExpanded} className={`navbar ${showMenuOverlay ? "active" : ""}`}>
        <Navbar.Toggle onClick={() => handleMenu()} className="hamberger menu-overlay">
          <HamburgerIcon isActive={showMenuOverlay} heroHeight={heroHeight.px}/>
        </Navbar.Toggle>
        
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="w-100 nav-justified align-items-center item">
            <div></div>
            <NavLink links={[{ to: "/", labelJa: "弊社について", labelEn: "About Us" }]} additionalClassName="d-block d-lg-none" />
            <NavLink links={firstHalfLinks} />

            {/* この部分はPCサイズ時に表示 */}
            <Nav.Item className="d-flex justify-content-center d-none d-lg-block">
              <LinkContainer to="/" onClick={() => handleMenu(false)}>
                <Image src="/images/header/corp_icon.svg" alt="JYU Toggle Icon" className="bland" />
              </LinkContainer>
            </Nav.Item>

            <NavLink links={secondHalfLinks} />
          </Nav>
        </Navbar.Collapse>
      </Navbar>

    </div>
  );
};

export default Header;
