import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Image } from "react-bootstrap";
import "./Contact.scss";

interface ContactProps {
  title: string;
  description?: string;
  displayForm: boolean;
  displayLineRegistration: boolean;
}

const Contact: React.FC<ContactProps> = ({ title, description, displayForm, displayLineRegistration }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const response = await fetch(
      `https://script.google.com/macros/s/AKfycbysc-l4DKVAnmKakMJ8Xkf-v-t2Yk2s_CTAWPm3R-FH1lWWYaykrbuleBdLsw1va0ILbg/exec?name=${formData.name}&email=${formData.email}&subject=${formData.subject}&message=${formData.message}`
    );

    if (response.ok) {
      alert("お問い合わせが送信されました。");
      setFormData({ name: "", email: "", subject: "", message: "" });
    } else {
      alert("送信に失敗しました。お手数ですがaizawa.yuta@jyu-innovation.comまでご連絡ください。");
    }
  };

  const formFields: {
    label: string;
    controlId: string;
    type: string;
    name: keyof typeof formData;
  }[] = [
    { label: "お名前", controlId: "formName", type: "text", name: "name" },
    { label: "メールアドレス", controlId: "formEmail", type: "email", name: "email" },
    { label: "件名", controlId: "formSubject", type: "text", name: "subject" },
    {
      label: "お問い合わせ内容",
      controlId: "formMessage",
      type: "textarea",
      name: "message",
    },
  ];

  const formColSize = displayLineRegistration ? 6 : 12;

  return (
    <>
      <h2 className="h2-title">
        <div className="english">Contact Us</div>
        <div className="japanese">{title}</div>
      </h2>

      <Container className="mb-5">
      {description &&
        <Row>
          <Col xs={11} className="mx-auto">
            <p className="p-base mb-5">{description}</p>
          </Col>
        </Row>
      }
        <Row className="mb-4">
          {displayForm && (
            <Col md={formColSize}>
              {displayLineRegistration && <h3 className="subsection-title">フォームでお問い合わせ</h3>}
              <Form onSubmit={handleSubmit}>
                {formFields.map((field, index) => (
                  <Form.Group as={Row} className="mb-2" controlId={field.controlId} key={field.controlId}>
                    <Form.Label column sm="3" md="3">
                      {field.label}
                    </Form.Label>
                    <Col sm="9" md="9">
                      <div className={`d-flex flex-column ${index === formFields.length - 1 ? "justify-content-between" : ""}`} style={{ height: "100%" }}>
                        <Form.Control
                          type={field.type}
                          as={field.type === "textarea" ? "textarea" : undefined}
                          rows={field.type === "textarea" ? 3 : undefined}
                          placeholder={field.label}
                          value={formData[field.name]}
                          name={field.name}
                          onChange={handleChange}
                        />
                        {index === formFields.length - 1 && (
                          <Button variant="light" type="submit" className="button mt-3 align-self-end">
                            送信
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Form.Group>
                ))}
              </Form>
            </Col>
          )}
          {displayLineRegistration && (
            <Col md={formColSize}>
              {displayForm && <h3 className="subsection-title">LINEでお問い合わせ</h3>}
              <Row className="align-items-center">
                <Col sm={5} className="d-flex flex-column align-items-center">
                  <a data-v-6fa6a74a="" href="https://lin.ee/wnSZ38u">
                    <Image src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="36rem" className="d-block" />
                  </a>
                </Col>
                <Col sm={1} className="d-flex flex-column align-items-center">
                  or
                </Col>
                <Col sm={6} className="d-flex flex-column align-items-center">
                  <Image src="https://qr-official.line.me/gs/M_750tuicc_BW.png?oat__id=2423315" rounded fluid style={{ maxWidth: "10rem" }} />
                </Col>
              </Row>
            </Col>
          )}
          {!displayForm && !displayLineRegistration && (
            <Col md={12}>
              <h3 className="subsection-title">下記メールアドレスにご連絡ください</h3>
              <Button variant="light" className="button mt-3 align-self-start">
                お問い合わせ
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Contact;
