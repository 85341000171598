import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './BlogTag.module.scss';

type BlogTagProps = {
  tag: string;
  to: string;
};

const BlogTile: React.FC<BlogTagProps> = ({ tag, to }) => {
  const navigate = useNavigate();
  
  return (
    <div
      className={styles.tag}
      onClick={() => {
        navigate(to === "" ? "/posts" : `/posts/tags/${to}`)
        window.scrollTo(0, 0)
      }}
    >
      {tag}
    </div>
  );
};

export default BlogTile;
