import React from 'react';
// import { Container, Row, Col, Image } from 'react-bootstrap';
import ImageWithDescription from '../common/ImageWithDescription';
import './OurCapability.scss';


interface SubContent {
  subtitle: string;
  text: string;
}

interface ServiceData {
  title: string;
  content: string | SubContent[];
  image: string | React.ReactNode; // imageをReactNode（Reactコンポーネント）も受け入れるように修正
  btn?: {
    path: string;
    label: string;
  };
}

interface OurCapabilityProps {
  servicesData: ServiceData[];
}

const OurCapability: React.FC<OurCapabilityProps> = ({servicesData}) => {
  return (
    <section className="it-consulting-our-capability">
      <h2 className="h2-title">
        <div className="english">Our Capability</div>
        <div className="japanese">提供サービス</div>
      </h2>
      <ImageWithDescription 
        data = {servicesData}
        rowHeight={30}
        imageToTextRatio={[6,6]}
        alternateLayout={false}
      />
    </section>
  );
};

export default OurCapability;
