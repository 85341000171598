import Hero from '../common/Hero';
import { HeroType } from '../../types/Hero';
import CircularText from "../common/CircularText3";
import ScrollingTransparentSquare from '../common/ScrollingTransparentSquare';
import { HeroHeightContext } from '../common/HeroHeightContext';
import { useCallback, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

interface HeroSectionProps{
  hero: HeroType;
}

const HeroSection: React.FC<HeroSectionProps> = ({hero}) => {
  const { setHeroHeight } = useContext(HeroHeightContext);

  const [styleParams, setStyleParams] = useState({
    textPosition: "7rem, -5rem",
    circularPosition: "25rem, -3.5rem",
    letterSpacing: "0.8rem",
    textFontSize: "2rem",
    circularTextRadius: 10,
    circularTextFontSize: 1.5,
    boxHeight: 0,
  });
  const location = useLocation();

  const handleResize = useCallback(() => {
    const width = Math.max(Math.min(window.innerWidth,1320),320);
    const newBoxHeight = Math.max(0.0001 * width * width - 0.227 * width + 175, 47); // 画面幅に応じた高さを計算
    setHeroHeight.px(newBoxHeight * window.innerWidth / 100 - 50);

    let newTextPosition, newCircularPosition, newLetterSpacing, newTextFontSize, newCircularTextRadius, newCircularTextFontSize;


    if (width < 576) {
      newTextPosition = "4rem, -2.5rem";
      newCircularPosition = "16.5rem, -2.8rem";
      newLetterSpacing = "0.3rem";
      newTextFontSize = "1.4rem";
      newCircularTextRadius = 6;
      newCircularTextFontSize = 0.9;
    } else if (width < 768) {
      newTextPosition = "2rem, -5rem";
      newCircularPosition = "12rem, -4.3rem";
      newLetterSpacing = "0.1rem";
      newTextFontSize = "1.5rem";
      newCircularTextRadius = 7;
      newCircularTextFontSize = 1.2;
    } else if (width < 992) {
      newTextPosition = "1.8rem, -1.5rem";
      newCircularPosition = "16rem, -2.3rem";
      newLetterSpacing = "0.5rem";
      newTextFontSize = "1.4rem";
      newCircularTextRadius = 6.2;
      newCircularTextFontSize = 1.1;
    } else {
      newTextPosition = "2.5rem, -3.5rem";
      newCircularPosition = "16rem, -4rem";
      newLetterSpacing = "0.35rem";
      newTextFontSize = "1.6rem";
      newCircularTextRadius = 6.5;
      newCircularTextFontSize = 1.1;
    }

    setStyleParams({
      textPosition: newTextPosition,
      circularPosition: newCircularPosition,
      letterSpacing: newLetterSpacing,
      textFontSize: newTextFontSize,
      circularTextRadius: newCircularTextRadius,
      circularTextFontSize: newCircularTextFontSize,
      boxHeight: newBoxHeight,
    });
  }, [setHeroHeight]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, location]);
  
  return (
    <section className="position-relative" style={{overflow: `hidden`}}>
      <Hero hero={hero} height={`${styleParams.boxHeight}vw`}/>
      <ScrollingTransparentSquare
          top={{initial:0, end:0}}
          bottom={{initial:25, end:5}}
          left={{initial:30, end:15}}
          right={{initial:0, end:0}}
          backgroundColor='rgba(92, 146, 145, 1)'
          boxHeight={styleParams.boxHeight}
          windowWidth={window.innerWidth}
      />
      <ScrollingTransparentSquare
          top={{initial:0, end:0}}
          bottom={{initial:0, end:0}}
          left={{initial:0, end:0}}
          right={{initial:70, end:85}}
          backgroundColor='rgba(192, 210, 121, 1)'
          boxHeight={styleParams.boxHeight}
          windowWidth={window.innerWidth}
      />
      <ScrollingTransparentSquare
          top={{initial:75, end:95}}
          bottom={{initial:0, end:0}}
          left={{initial:0, end:0}}
          right={{initial:0, end:0}}
          backgroundColor='rgba(239, 214, 89, 1)'
          boxHeight={styleParams.boxHeight}
          windowWidth={window.innerWidth}
      />
      <div
        className="position-absolute bottom-0 start-0"
        style={{
          transform:`translate(${styleParams.textPosition})`
        }}
        >
        <p
          className="lead"
          style={{
            color: "white",
            fontSize: styleParams.textFontSize,
            letterSpacing: styleParams.letterSpacing
          }}
        >
        柔軟な発想と多彩な仲間で<br />イノベーションを
        </p>
      </div>
      <div
        className="position-absolute bottom-0 start-0"
        style={{
          transform:`translate(${styleParams.circularPosition})`,
        }}
        >
        <CircularText
          text="Fostering innovation through flexible minds and diverse partnerships. "
          // startAngle={-85}
          // endAngle={205} 
          startAngle={240}
          endAngle={535}
          radius={styleParams.circularTextRadius}
          fontSize={styleParams.circularTextFontSize}
          duration={30}
        />
      </ div>
    </section>
  );
};

export default HeroSection;
