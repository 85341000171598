import React from 'react';
import { BlogPost } from '../../types/BlogPost';
import { useNavigate } from 'react-router-dom';
import BlogTag from './BlogTag';
import './BlogTile.scss';

type BlogTileProps = {
  post: BlogPost;
};

const BlogTile: React.FC<BlogTileProps> = ({ post }) => {
  const navigate = useNavigate();
  
  return (
      <div
        onClick={() => {
          navigate(`/posts/${post.id}`)
          window.scrollTo(0, 0)
        }}
        className = "blog-tile"
      >
        <div className="img-wrap">
          <div className="img" style={{backgroundImage: `url(${post.eyecatch})`}}/>
          <div className="overlay" />
          <div className="tags">
            {post.tags.map((tag, index) => (
              <BlogTag key={index} tag={tag} to={tag} />
            ))}
          </div>
        </div>
        <div className="title text-center">{post.title}</div>
      </div>
  );
};

export default BlogTile;
