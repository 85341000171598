import { createContext, useState, useMemo } from 'react';

interface HeroHeightValue {
  vw: number;
  px: number;
  rem: number;
}

interface HeroHeightContextProps {
  heroHeight: HeroHeightValue;
  setHeroHeight: {
    vw: (value: number) => void;
    px: (value: number) => void;
    rem: (value: number) => void;
  };
}

export const HeroHeightContext = createContext<HeroHeightContextProps>({
  heroHeight: { vw: 0, px: 0, rem: 0 },
  setHeroHeight: {
    vw: () => {},
    px: () => {},
    rem: () => {}
  }
});

interface HeroHeightProviderProps {
  children: React.ReactNode;
}

export const HeroHeightProvider: React.FC<HeroHeightProviderProps> = ({ children }) => {
  const [heroHeight, _setHeroHeight] = useState<HeroHeightValue>({ vw: 0, px: 0, rem: 0 });

  const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

  const setHeroHeight = useMemo(() => ({
    vw: (value: number) => {
      _setHeroHeight({
        vw: value,
        px: (value / 100) * window.innerWidth,
        rem: ((value / 100) * window.innerWidth) / rootFontSize
      });
    },
    px: (value: number) => {
      _setHeroHeight({
        vw: (value / window.innerWidth) * 100,
        px: value,
        rem: value / rootFontSize,
      });
    },
    rem: (value: number) => {
      const pxValue = value * rootFontSize;
      _setHeroHeight({
        vw: (pxValue / window.innerWidth) * 100,
        px: pxValue,
        rem: value,
      });
    }
  }), [rootFontSize]);

  return (
    <HeroHeightContext.Provider value={{ heroHeight, setHeroHeight }}>
      {children}
    </HeroHeightContext.Provider>
  );
};
