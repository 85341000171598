import React, { useState, useRef, useEffect } from 'react';
import { Container} from 'react-bootstrap';
import './ImageWithDescription2.scss';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '../common/Utils';


interface SubContent {
  subtitle: string;
  text: string;
}

interface Item {
  title: string;
  content: string | SubContent[];
  image: string | React.ReactNode;
  btn?: {
    path: string;
    label: string;
  };
}

interface ImageWithDescriptionProps {
  data: Item[];
  rowHeight: number;
  imageRatio?: number;
  verticalMargin?: number;
  alternateLayout?: boolean;
}

const ImageWithDescription: React.FC<ImageWithDescriptionProps> = ({ data, rowHeight, imageRatio = 60, alternateLayout = true }) => {
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const handleClick = (path: string) => {
    if (path.startsWith('http://') || path.startsWith('https://')) {
      // 外部リンクの場合は新しいタブで開く
      window.open(path, '_blank', 'noopener,noreferrer');
    } else {
      // 内部リンクの場合はreact-router-domのnavigateを使用する
      window.scrollTo(0, 0);
      navigate(path);
    }
  };

  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  const imageRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    imageRefs.current = imageRefs.current.slice(0, data.length);
    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('zoom');
          observer && observer.unobserve(entry.target);
        }
      });
    };
    const observerOptions = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0
    };
    const observer = new IntersectionObserver(callback, observerOptions);
    imageRefs.current.forEach((ref) => ref && observer.observe(ref));
    setObserver(observer);
  }, [data]);


  useEffect(() => {
    return () => {
      observer && observer.disconnect();
    };
  }, [observer]);
  
  return (
    <div className="image-with-description2">
      {data.map((item, index) => {
        const isSmallScreen = windowSize.width <= 768;
        const isAlternate = alternateLayout ? index % 2 === 0 : true;
        const btn = item.btn;
        const verticalMargin = isSmallScreen ? 8 : 2; //rem
        const backgroundColorEven = "rgba(255, 255, 255, 1)";
        const backgroundColorOdd = "rgba(240, 240, 240, 1)";
        const overlapRatio = 20;
        const textRatio = Math.min(100, 100 - imageRatio + overlapRatio); //20%重ねる
        const itemHeight = isSmallScreen ? rowHeight*(2-overlapRatio/100) : rowHeight; //rem

        return (
          <div
            key={index}
            className="item-wrap"
            style={{ 
              height: `${itemHeight+verticalMargin*2}rem`, 
              backgroundColor: index % 2 === 0 ? backgroundColorEven : backgroundColorOdd,
            }}
            >
            <Container
              className="item"
              style={{
                width: "100%",
                height: `${itemHeight}rem`,
                margin: "auto"
              }}>
              {
                typeof item.image === "string" ?
                <div
                  style={{
                    width: `${isSmallScreen ? 100 : imageRatio}%`,
                    height: `${rowHeight}rem`,
                    backgroundImage: `linear-gradient(120deg, ${isAlternate ? backgroundColorEven : backgroundColorOdd} 0%, rgba(255, 255, 255, 0) 60%), url(${item.image})`,
                    backgroundSize: 'cover',
                  }}
                  className="item-image"
                  ref={el => imageRefs.current[index] = el} //for animation
                  />
                :
                <div
                  style={{
                    width: `${isSmallScreen ? 100 : imageRatio}%`,
                    backgroundImage: `linear-gradient(120deg, ${isAlternate ? backgroundColorEven : backgroundColorOdd} 0%, rgba(255, 255, 255, 0) 60%)`,
                    backgroundSize: 'cover',
                  }}
                  className="item-image"
                  ref={el => imageRefs.current[index] = el} //for animation
                  >
                  {item.image}
                </div>
              }

              <div
                className="item-text"
                style={{
                  width: `${isSmallScreen ? 98 : textRatio}%`,
                  height: `${rowHeight}rem`,
                }}  
              >
                <h3 className="h3-title">{item.title}</h3>
                {Array.isArray(item.content) ? (
                  item.content.map((contentItem, contentIndex) => (
                    <div key={contentIndex} className="detail-text">
                      <h4 className="h4-title">{contentItem.subtitle}</h4>
                      <p className="p-base">{contentItem.text}</p>
                    </div>
                  ))
                ) : (
                  <p className="p1" style={{fontWeight: "300"}}>{item.content}</p>
                )}
                {btn && (  // ここで一時変数を使います
                  <p onClick={() => handleClick(btn.path)} className="detail-button btn btn-light">
                    {btn.label}
                  </p>
                )}
              </div>
            </Container>
          </div>
        );
      })}
    </div>
  );
}

export default ImageWithDescription;