import './Hero.scss';
import { HeroType } from '../../types/Hero';
import React from 'react';

interface HeroProps {
  hero: HeroType;
  height?: string;
}

const Hero: React.FC<HeroProps> = ({ hero, height = '100vh' }) => {
  const overlayColor = hero.overlayColor || 'rgba(0,0,0,0)';

  return (
    <div className="hero" style={{ position: 'relative', height: height }}>
      {hero.imageUrl && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            objectFit: "cover",
            backgroundImage: `url(${hero.imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>
      )}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: overlayColor,
          mixBlendMode: 'multiply',
        }}
      ></div>

      {hero.imageIcon && (
        <img
          src={hero.imageIcon}
          alt="icon"
          className="hero-image-icon"
        />
      )}
    </div>
  );
};

export default Hero;
